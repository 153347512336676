

body {
    background-color: black !important;
}

.root {
    background-color: black !important;
}

.gallery {
    width: 100vw;
    /* height: 100vh; */
    color: rgba(255, 255, 255, 0.8);
    /* background-color: black; */

    font-family: Baskerville;
}


.gallery h1 {
    font-family: Karla;
    padding: 20px;
    
}


.galleryDescription {
    margin-left: auto;
    margin-right: auto;
    width: 90%;

    padding: 50px 0px;


}

.unpadTop {
    padding-top: 0px;
}

.galleryDescription h1 {
    font-family: 'Karla';
}

.galleryDescription p {
    font-family: 'Oswald';
    font-size: 18px;
    letter-spacing: 1px;
}



/* Image Previewing */


.imagePreview {

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0, 0.8);

}

.imagePreview img {
    position: relative;
    top: 70px;
    left: 70px;
    width: calc(100% - 140px);
    height: 100%;
    height: calc(100% - 100px);
    object-fit: contain;
}



/* Bio Media */
@media only screen and (max-width: 600px) {

    .gallery {
        padding-left: 0;
    }

    .galleryDescription {
        padding-left: 0;
        
    }

    .galleryDescription p {
        font-size: 15px;
    }



    .imagePreview img {
        position: relative;
        top: 70px;
        left: 10px;
        width: calc(100% - 20px);
        height: 100%;
        height: calc(100% - 100px);
        object-fit: contain;
    }


}


