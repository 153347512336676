

.slideShowContainer {

    width: 100%;
    height: 100%;

    overflow: hidden;

}

.slideShowImg {

    width: 100%;
    height: 100%;

    object-fit: cover;
    /* object-fit: contain; */

}