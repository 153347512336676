

.bioContainer {

    width: 100%;
    /* max-height: 500px; */
    overflow: hidden;
    margin-bottom: 50px;
}


.bioImgContainer {

    width: 40%;
    height: 100%; 
    object-fit: contain;
    float: left;
}

.animatedBioImgContainer {
    transition: ease-in-out 0.3s all;
}

.animatedBioImgContainer:hover {
    opacity: 0.8;
}

.bioImg {

    width: 80%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
}

.bioText {
    padding: 20px;
    float: left;
    width: calc(60% - 40px);
    height: 100%;
    font-size: 18px;
    max-width: 900px; 
    /* font-family: Karla; */
    line-height: 35px;

}

.bioText h1 {
    font-family: 'Karla';
}

.bioText p{
    font-family: 'Oswald';
}


/* Bio Media */
@media only screen and (max-width: 600px) {

    .bioImgContainer {
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    
    .bioContainer {
        max-height: none;
        margin-left: none;
    }

    .bioImg {
        width: 100%;
        padding: 0;
        border-radius: 0px;
    }

    .bioText {
        width: calc(100% - 40px);
        padding-left: 20px;
    }

}


