

.root {

    background-color: rgb(255, 255, 255);

    /* margin-top: 80px; */

    width: 100vw;
    height: calc(100vh - 60px);

    /* Set global font */
    /* font-family: 'Oswald'; */
    font-family: 'Karla';

    transition: all 0.15s ease-in-out;

}



/* starting ENTER animation */
.messageout-enter {
    opacity: 0.1;
    margin-left: 0;
  }
  
/* ending ENTER animation */
.messageout-enter-active {
    opacity: 1;
    transition: all 2000ms linear;
    margin-left: 500;
}

/* starting EXIT animation */
.messageout-exit {
    opacity: 1;
    margin-left: 500;
}

/* ending EXIT animation */
.messageout-exit-active {
    opacity: 0;
    margin-left: 200;
    transition: all 2000ms ease-in-out;
}
