body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.invert {
  filter: invert(1);
}

.indentText {
  text-indent: 50px;
}


.fadeQuick {
  animation: fadeQuick ease 1s;
  -webkit-animation: fadeQuick ease 1s;
  -moz-animation: fadeQuick ease 1s;
  -o-animation: fadeQuick ease 1s;
  -ms-animation: fadeQuick ease 1s;
}
@keyframes fadeQuick {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeQuick {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeQuick {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-o-keyframes fadeQuick {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-ms-keyframes fadeQuick {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
}
}