

.Header{
    position: fixed;

    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    margin-bottom: 100px;
    background-color: rgba(0, 0, 0, 1);
    color: white;

    /* padding-top: 10px;
    padding-left: 10px; */
    font-family: 'Baskerville';
    font-size: 20px;

    z-index: 5;

    cursor: default;

    transition: height 0.15s linear ;
}

.HeaderLogo {
    height: 60px;
    
    position: relative;
    left: 35px;
    /* opacity: 0.7; */
    filter: invert(1);
    opacity: 1;
    transition: ease-in-out 0.3s all;

}

.HeaderLogo:hover {
    opacity: 0.8;
}

.LogoText {
    height: 60px;
    line-height: 60px;
    margin-left: 20px;
    letter-spacing: 5px;
    float: left;
    cursor: pointer;
    transition: ease-in-out 0.3s all;
}

.LogoText:hover {
    color: rgb(172, 185, 241);
}

.HeaderTitle {
    display: inline-block;
    float: left;
    transition: ease-in-out 0.3s all;
}

.HeaderTitle:hover {
    color: rgb(172, 185, 241);

}

.HeaderOption {
    float: right;
    margin: 0px;
    margin-right: 25px;
    height: 60px;
    line-height: 60px;
    cursor: pointer;
    transition: ease-in-out 0.3s all;
    /* letter-spacing: 5px; */

    font-family: 'Oswald';
    
}

.HeaderOption:hover {
    color: rgb(172, 185, 241);
}

.HeaderActive {
    color: rgb(172, 185, 241);
}

.HeaderDropdown {
    position: fixed; 
    top: 60px;
    display: none;
    height: 0px;
    background-color: rgba(0, 0, 0, 0.85);
    width: 200px;
    color: white;
    font-family: "Baskerville";

    transition: height 0.15s linear ;
}


.headerOpener {
    display: none;
    float: right;
    margin: 0px;
    margin-right: 25px;
    height: 16px;
    line-height: 60px;
    cursor: pointer;
    transition: ease-in-out 0.3s all;
    position: relative;
    top: 22px;
}


.navBar {
    height: calc(100vh - 60px);
    position: fixed;
    top: 60px;
    right: 0;
    background-color: rgba(0,0,0, 0.8);
    /* background-color: blue; */
    width: 0px;
    transition: ease-in-out 0.3s all;
}

.navBarOpen {
    width: 40vw;
    z-index: 50;
}

.navOpt {
    text-align: center;
    padding: 10px 0px;
    font-family: 'Oswald';
}

.navOptActive {
    color: rgb(172, 185, 241);
}

.navBarExiter {
    z-index: 49;
    width: 100vw;
    height: 100vh;
}




/* starting ENTER animation */
.messageout-enter {
    height: 0px;
    display: block;
}

/* ending ENTER animation */
.messageout-enter-active {
    /* opacity: 1; */
    height: 100px;
    transition: all 500ms linear;
}

/* starting EXIT animation */
.messageout-exit {
    height: 100px;
}

/* ending EXIT animation */
.messageout-exit-active {
    height: 0px;
    transition: all 500ms ease-in-out;
}
  


@media only screen and (max-width: 600px) {
    .HeaderOption {
        display: none !important;
    }
    .headerOpener {
        display: block;
    }

    .Header .headerOpener {
      float: right;
      display: block;
    }
}

  