

.Footer {

    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 40px;
    background-color: rgba(0,0,0,0.85);

    cursor: pointer;

    z-index: 100;

}


.footerIcon {

    float: right;

    position: relative;
    height: 60%;
    top: 20%;

    margin: 0px 20px;

    opacity: 0.7;

    transition: ease-in-out 0.3s all;

}

.footerIcon:hover {
    opacity: 1;
}



.contactInfo {
    color: white;
    font-family: 'Oswald';
    float: left;
    height: 40px;
    line-height: 40px;

    margin-left: 20px;

    opacity: 0.7;
}


/* Footer Media */
@media only screen and (max-width: 600px) {

    .Footer {
        height: 30px;
    }

    .contactInfo {
        height: 30px;
        line-height: 30px;
        font-size: 14px;
    }

    .footerIcon {
        margin: 0 10px;
    }


}