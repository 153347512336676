

body {
    background-color: black !important;
}

.root {
    background-color: black !important;
}

.galleryPage {
    width: 100vw;
    /* height: 100vh; */
    color: rgba(255, 255, 255, 0.8);
    /* background-color: black; */

    font-family: Baskerville;
    margin-bottom: 150px;
}


.galleryPage h1 {
    font-family: Karla;
    padding: 0px;
    
}



/* Bio Media */
@media only screen and (max-width: 600px) {

    .galleryPage {
        padding-left: 0;
    }


}