

body {
    background-color: black !important;
}

.root {
    background-color: black !important;
}

.homePage {
    width: 100vw;
    /* height: 100vh; */
    color: rgba(255, 255, 255, 0.8);
    /* background-color: black; */

    font-family: Baskerville;

    margin-bottom: 50px;
}


.homePage h1 {
    font-family: Oswald;
    padding: 0px;
    letter-spacing: 3px;
    font-size: 24px;
    
}


.headerImage {
    width: 100%; 
    height: 70vh;
    max-height: 1000px;
    /* overflow: hidden; */
    position: relative;
}


.headerTitleBlock {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    width: calc(100% - 150);
    margin-left: 150px;
    font-family: 'Oswald';
    letter-spacing: 3px;
}

/* .headerTitle {
    
}

.headerTitle2 {

} */

.qualificationRow {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 80px;
    margin-left: 150px;
    /* background-color: rgba(0,0,0,0.5); */
}

.qualificationRow img {
    height: 100%;
    float: left;
    padding: 0px 20px;
}


.headerBio {

    font-family: 'Oswald';
    padding: 50px 150px;
    


}


/* Bio Media */
@media only screen and (max-width: 600px) {

    .headerImage {
        height: 45vh;
        max-height: 500px;
    }

    .headerBio {
        padding: 50px 25px;
    }


    .qualificationRow {
        height: 60px;
        margin-left: 0px;
    }

    .headerTitleBlock {
        margin-left: 25px;
        font-size: 24px;
    }

}


